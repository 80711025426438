.lds-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 191px;
}

.lds-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 95px;
  height: 90px;
}

.lds-grid div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #103e64;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  animation-delay: -1.6s;
}

@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
