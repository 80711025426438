* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  background-color: #1a337f;
  font-family: "Source Sans 3", sans-serif;
}

header {
  margin-bottom: 5rem;
}

main {
  margin-bottom: 5rem;
}

footer img {
  display: block;
  width: 200px;
}
